import React from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Link } from "@mui/material";
import { CONTACT_EMAIL } from "../res/strings";

interface PrivacyPolicyAppProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, PrivacyPolicyAppProps>(() => ({
    box: {
        padding: 30,
        marginBottom: 30,
    },
    boxContent: (props) => ({
        maxWidth: props.contentWidth,
        marginLeft: "auto",
        marginRight: "auto",
    }),
}));

export default function PrivacyPolicyApp(props: PrivacyPolicyAppProps) {
    const classes = useStyles(props);
    return (
        <Box className={classes.box}>
            <div className={classes.boxContent}>
                <Typography variant="h4">Datenschutzerklärung</Typography>
                SaniAlarm hat die SaniAlarm App als eine kommerzielle App entwickelt. Dieser SERVICE wird von SaniAlarm zur Verfügung gestellt und ist für die Nutzung in der vorliegenden Form vorgesehen.

                Diese Seite dient dazu, Besucher über unsere Richtlinien bezüglich der Erfassung, Verwendung und Offenlegung persönlicher Daten zu informieren, falls sich jemand für die Nutzung unseres Dienstes entscheidet.

                Wenn Sie sich für die Nutzung unseres Dienstes entscheiden, erklären Sie sich mit der Erfassung und Verwendung von Informationen im Rahmen dieser Richtlinie einverstanden. Die von uns gesammelten persönlichen Daten werden für die Bereitstellung und Verbesserung des Dienstes verwendet. Wir werden Ihre Daten nur wie in dieser Datenschutzrichtlinie beschrieben verwenden oder weitergeben.

                Die in dieser Datenschutzrichtlinie verwendeten Begriffe haben dieselbe Bedeutung wie in unseren Allgemeinen Geschäftsbedingungen, die unter SaniAlarm eingesehen werden können.

                <Typography variant="h6">Anbieter der App</Typography>
                <Typography variant="body2" gutterBottom>
                    Die SaniAlarm App wird durch Claudius Kienle angeboten.
                    Kontaktdaten des Anbieters sind im Impressum der Website aufzufinden.
                </Typography>

                <Typography variant="h6">Zugriffsrechte</Typography>
                <Typography variant="body2" gutterBottom>
                    Für die korrekte Funktionsweise der App werden auf iOS und Android folgende
                    Berechtigungen benötigt:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1">Speicher</Typography>
                        <Typography variant="body2" gutterBottom>
                            In der App ist es möglich einen eigenen Alarmton zu hinterlegen.
                            Hierfür wird die Berechtigung benötigt, auf den internen Speicher zugreifen zu können.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Vibrationsalarm steuern und Ruhezustand deaktivieren</Typography>
                        <Typography variant="body2" gutterBottom>
                            Um eine schnelle und zuverlässige Alarmierung zu ermöglichen,
                            benötigt die App die Berechtigung, den aktivierten Ruhezustand
                            zu deaktivieren, sowie Vibrationsintensität und Lautsprecherlautstärke
                            steuern zu können.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Kritische Alarme senden</Typography>
                        <Typography variant="body2" gutterBottom>
                            Auf iOS Geräte wird die Berechtigung benötigt, kritische Alarme senden zu dürfen. Dies ermöglicht Benachrichtigung mit Ton und Vibration auch während der Nicht-Stören aktiv ist anzuzeigen.
                        </Typography>
                    </li>
                </ul>

                <Typography variant="h6">Welche Daten werden gespeichert</Typography>
                <Typography variant="body2" gutterBottom>
                    Um Ihnen die Nutzung unseres Dienstes zu ermöglichen, können wir Sie auffordern, uns bestimmte personenbezogene Daten zur Verfügung zu stellen:
                </Typography>
                <ul>
                    <li>Nutzername</li>
                    <li>Geschlecht</li>
                    <li>E-Mail</li>
                    <li>Passwort</li>
                    <li>ID des Nutzers</li>
                    <li>Gruppe, der der Nutzer zugehörig ist</li>
                    <li>Gerätespeziefische ID für die Alarmierung</li>
                </ul>
                <Typography variant="subtitle1">Log-Daten</Typography>
                <Typography variant="body2" gutterBottom>
                    Wir möchten Sie darüber informieren, dass wir bei der Nutzung unseres Dienstes im Falle eines Fehlers in der App Daten und Informationen (durch Produkte von Drittanbietern) auf Ihrem Telefon sammeln, die sogenannten Protokolldaten. Diese Protokolldaten können Informationen wie die Internetprotokolladresse ("IP") Ihres Geräts, den Gerätenamen, die Version des Betriebssystems, die Konfiguration der App bei der Nutzung unseres Dienstes, die Uhrzeit und das Datum Ihrer Nutzung des Dienstes und andere Statistiken enthalten.
                </Typography>
                <Typography variant="subtitle1">Cookies</Typography>
                <Typography variant="body2" gutterBottom>
                    Cookies sind Dateien mit einer kleinen Menge an Daten, die üblicherweise als anonyme eindeutige Identifikatoren verwendet werden. Sie werden von den Websites, die Sie besuchen, an Ihren Browser gesendet und im internen Speicher Ihres Geräts gespeichert.

                    Dieser Dienst verwendet diese "Cookies" nicht ausdrücklich. Die App kann jedoch Code und Bibliotheken von Drittanbietern verwenden, die "Cookies" nutzen, um Informationen zu sammeln und ihre Dienste zu verbessern. Sie haben die Möglichkeit, diese Cookies entweder zu akzeptieren oder abzulehnen und zu erfahren, wann ein Cookie an Ihr Gerät gesendet wird. Wenn Sie sich entscheiden, unsere Cookies abzulehnen, können Sie möglicherweise einige Teile dieses Dienstes nicht nutzen.
                </Typography>
                <Typography variant="h6">Wie lange werden die Daten gespeichert</Typography>
                <Typography variant="body2" gutterBottom>
                    Alle erhobenen Nutzerbezogenen Daten werden so früh als möglich von den
                    Servern gelöscht.
                    Dies geschieht spätestens, wenn der Nutzer in der App die Gruppe löscht
                    oder der entsprechende Nutzer aus der Gruppe entfernt wird.
                </Typography>
                <Typography variant="h6">Wo werden die Daten gespeichert</Typography>
                <Typography variant="body2" gutterBottom>
                    Alle Daten werden auf den SaniAlarm Servern gespeichert.
                    Diese werden durch <Link href="https://www.hetzner.com/legal/privacy-policy" >Hetzner</Link> gehostet. Alle SaniAlarm Server befinden
                    sich in dem Hetzner Datencenter Falkenstein, Deutschland.
                </Typography>
                <Typography variant="h6">Sicherheit</Typography>
                <Typography variant="body2" gutterBottom>
                    Wir wissen es zu schätzen, dass Sie uns Ihre persönlichen Daten anvertrauen, und bemühen uns daher, sie mit kommerziell akzeptablen Mitteln zu schützen. Denken Sie jedoch daran, dass keine Methode der Übertragung über das Internet oder der elektronischen Speicherung zu 100 % sicher und zuverlässig ist, und wir können keine absolute Sicherheit garantieren.
                </Typography>
                <Typography variant="h6">Links zu anderen Seiten</Typography>
                <Typography variant="body2" gutterBottom>
                    Dieser Dienst kann Links zu anderen Websites enthalten. Wenn Sie auf einen Link eines Dritten klicken, werden Sie zu dieser Site weitergeleitet. Beachten Sie, dass diese externen Sites nicht von uns betrieben werden. Wir raten Ihnen daher dringend, die Datenschutzrichtlinien dieser Websites zu lesen. Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder die Praktiken von Websites oder Diensten Dritter.
                </Typography>
                <Typography variant="h6">Arbeit mit Dritten</Typography>
                <Typography variant="body2" gutterBottom>
                    Die personenbezogenen Daten der Nutzer werden ausschließlich auf Servern die in Deutschland lokalisiert sind gespeichert und verarbeitet.

                    Um eine korrekte und reibungslose Funktionsweise der App zu gewährleisten, verwendet SaniAlarm die <Link href="https://www.google.com/policies/privacy/">Google Play Services</Link>.
                    Für die Analyse der SaniAlarm App, wird <Link href="https://firebase.google.com/policies/analytics">Google Analytics für Firebase</Link> verwendet.
                    Dieser Dienst erfasst die anonymisierte Interaktion der Nutzer mit der App und wird von uns eingesetzt um die App bestmöglich an die Anforderungen unserer Nutzer anzupassen. Die Erfassung durch Google Analytics kann in der App ausgeschalten werden.

                    Des sowie setzt SaniAlarm <Link href="https://firebase.google.com/support/privacy">Firebase Crashlytics</Link> ein und um mögliche Probleme frühzeitig zu erkennen. Dieser Dienst ermöglicht uns Absturzberichte der App zu sammeln und dadurch technische Probleme zu beheben.

                    Die In-App Bezahlung über PayPal oder Kreditkarte wird mittels <Link href="https://www.braintreepayments.com/de/legal/braintree-privacy-policy">Braintree</Link> bereitgestellt.
                </Typography>
                <Typography variant="h6">Löschen der Daten</Typography>
                <Typography variant="body2" gutterBottom>
                    Ihre Daten werden instantan nach dem Löschen der entsprechenden Gruppe entfernt.
                    Sie können sich an <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> wenden,
                    wenn Sie Fragen bezüglich dem Löschen Ihrer Daten haben.
                </Typography>
                <Typography variant="h6">Änderungen an dieser Datenschutzrichtlinie</Typography>
                <Typography variant="body2" gutterBottom>
                    Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir empfehlen Ihnen daher, diese Seite regelmäßig auf etwaige Änderungen zu überprüfen. Wir werden Sie über alle Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.

                    Diese Richtlinie wurde zuletzt an dem 10. September 2024 aktualisiert.
                </Typography>
                <Typography variant="h6">Kontaktieren Sie uns</Typography>
                <Typography variant="body2" >
                    Wenn Sie Fragen oder Anregungen zu unserer Datenschutzpolitik haben, zögern Sie nicht, uns unter <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> zu kontaktieren.
                </Typography>
            </div>
        </Box >
    );
}
